var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
var _a;
import { i18n, KEYS } from '../../i18n';
import { BaseUserAppTYpe } from '../../stores/common';
import { OrderLocationMode, OrderRouteType } from '../../models';
var OPOSITE_USERS = (_a = {},
    _a[BaseUserAppTYpe.CARRIER] = BaseUserAppTYpe.CUSTOMER,
    _a[BaseUserAppTYpe.CUSTOMER] = BaseUserAppTYpe.CARRIER,
    _a);
var mapLocUsers = function (f) {
    return ((f === null || f === void 0 ? void 0 : f.email) || []).map(function (email, index) {
        var _a, _b, _c, _d, _e, _f, _g;
        return ({
            _id: email,
            label: [((_a = f.origin) === null || _a === void 0 ? void 0 : _a.members[index]) && f.origin.members[index].mame, (_b = f.origin) === null || _b === void 0 ? void 0 : _b.companyName, email]
                .filter(function (e) { return e; })
                .join(', '),
            user: {
                email: email,
                phone: ((_c = f.origin) === null || _c === void 0 ? void 0 : _c.members[index]) && f.origin.members[index].phone,
                firstName: ((_d = f.origin) === null || _d === void 0 ? void 0 : _d.members[index]) && f.origin.members[index].name,
                lastName: '',
                appType: [f.type === OrderRouteType.Preferred ? BaseUserAppTYpe.SENDER : BaseUserAppTYpe.CONSIGNEE],
            },
            email: email,
            phone: ((_e = f.origin) === null || _e === void 0 ? void 0 : _e.members[index]) && f.origin.members[index].phone,
            firstName: ((_f = f.origin) === null || _f === void 0 ? void 0 : _f.members[index]) && f.origin.members[index].name,
            lastName: '',
            appType: [f.type === OrderRouteType.Preferred ? BaseUserAppTYpe.SENDER : BaseUserAppTYpe.CONSIGNEE],
            company: {
                name: ((_g = f.origin) === null || _g === void 0 ? void 0 : _g.companyName) || '',
            },
        });
    });
};
// .reduce((a: string[], b: string[]) => [...a, ...b], []);
export var documentInitialValues = function (order, currentUser, props, onlyUsers) {
    var _a, _b, _c;
    var _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6;
    if (onlyUsers === void 0) { onlyUsers = false; }
    var isCarrierFromCustomerList = !(order === null || order === void 0 ? void 0 : order.createdBy.encoded) && ((_f = (_e = (_d = props.quote) === null || _d === void 0 ? void 0 : _d.createdBy) === null || _e === void 0 ? void 0 : _e.company.companiesParents) === null || _f === void 0 ? void 0 : _f.length) && ((_h = (_g = props.quote) === null || _g === void 0 ? void 0 : _g.createdBy) === null || _h === void 0 ? void 0 : _h.company.companiesParents.map(function (e) { return e.toString(); }).includes((_l = (_k = (_j = order === null || order === void 0 ? void 0 : order.createdBy) === null || _j === void 0 ? void 0 : _j.company) === null || _k === void 0 ? void 0 : _k._id) === null || _l === void 0 ? void 0 : _l.toString()));
    var isOwnOrder = ((_o = (_m = order === null || order === void 0 ? void 0 : order.manager) === null || _m === void 0 ? void 0 : _m._id) === null || _o === void 0 ? void 0 : _o.toString()) === currentUser._id.toString() ||
        (order === null || order === void 0 ? void 0 : order.createdBy.toString()) === currentUser._id.toString();
    var initialValues = {
        users: (order.transportations || [])
            .map(function (e) { return e.routes.map(mapLocUsers).reduce(function (a, b) { return __spread(a, b); }, []); })
            .reduce(function (a, b) { return __spread(a, b); }, []),
        emails: __spread(new Set(__spread((order.transportations || [])
            .map(function (e) { return e.routes.map(function (f) { return f.email; }).reduce(function (a, b) { return __spread(a, b); }, []); })
            .reduce(function (a, b) { return __spread(a, b); }, [])))).filter(function (e) { return e; }),
    };
    if ([BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].find(function (t) { return currentUser.appType.includes(t); })) {
        if (props.isPreparation) {
            initialValues.users = initialValues.users.filter(function (userInfo) { return userInfo.user.email !== currentUser.email; });
        }
        else {
            initialValues.users = [];
        }
    }
    try {
        var users = [];
        if ([BaseUserAppTYpe.CARRIER].find(function (t) { return currentUser.appType.includes(t); })) {
            initialValues.emails = [];
            initialValues.users = [];
        }
        if ([BaseUserAppTYpe.CARRIER, BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].find(function (t) {
            return currentUser.appType.includes(t);
        })) {
            if (((_p = order === null || order === void 0 ? void 0 : order.forwarders) === null || _p === void 0 ? void 0 : _p.length) && !isCarrierFromCustomerList) {
                initialValues.emails.push(order.forwarders[0].email);
                users.push(order.forwarders[0]);
            }
            else if (order) {
                initialValues.emails.push(order === null || order === void 0 ? void 0 : order.createdBy.email);
                users.push(order === null || order === void 0 ? void 0 : order.createdBy);
            }
        }
        else if (currentUser.appType.includes(BaseUserAppTYpe.CUSTOMER)) {
            if (props.isPreparation) {
                users.push.apply(users, __spread((order.transportations || [])
                    .map(function (e) {
                    return e.routes
                        .map(function (f) {
                        var _a, _b, _c;
                        // console.log(f.origin.members); //
                        if (((_a = f.origin) === null || _a === void 0 ? void 0 : _a.mode) === OrderLocationMode.ADVANCED) {
                            return __spread((((_b = f.origin) === null || _b === void 0 ? void 0 : _b.members) || []), (f.origin.additionalMembers || [])).map(function (_a) {
                                var email = _a.email, phone = _a.phone, name = _a.name;
                                return ({
                                    _id: email,
                                    label: [name, f.origin.companyName, email].filter(function (e) { return e; }).join(', '),
                                    email: email,
                                    phone: phone,
                                    firstName: name,
                                    lastName: '',
                                    appType: [
                                        f.type === OrderRouteType.Preferred ? BaseUserAppTYpe.SENDER : BaseUserAppTYpe.CONSIGNEE,
                                    ],
                                    company: {
                                        name: f.origin.companyName,
                                    },
                                });
                            });
                        }
                        return mapLocUsers(__assign(__assign({}, f), { email: (_c = f.origin) === null || _c === void 0 ? void 0 : _c.email }));
                    })
                        .reduce(function (a, b) { return __spread(a, b); }, []);
                })
                    .reduce(function (a, b) { return __spread(a, b); }, [])));
            }
            if (props.isPreparation && !isOwnOrder) {
                initialValues.users = initialValues.users.filter(function (userInfo) { return userInfo.user.email !== currentUser.email; });
                users.push((order === null || order === void 0 ? void 0 : order.manager) || (order === null || order === void 0 ? void 0 : order.createdBy));
            }
            else {
                if (order && ((_q = order === null || order === void 0 ? void 0 : order.forwarders) === null || _q === void 0 ? void 0 : _q.length) && !isCarrierFromCustomerList) {
                    initialValues.emails.push(order.forwarders[0].email);
                    users.push(order.forwarders[0]);
                }
                else if ((_r = props.quote) === null || _r === void 0 ? void 0 : _r.createdBy) {
                    initialValues.emails.push((_s = props.quote) === null || _s === void 0 ? void 0 : _s.createdBy.email);
                    users.push((_t = props.quote) === null || _t === void 0 ? void 0 : _t.createdBy);
                }
            }
        }
        else if (currentUser.appType.includes(BaseUserAppTYpe.WAREHOUSE)) {
            if (order && ((_u = order === null || order === void 0 ? void 0 : order.forwarders) === null || _u === void 0 ? void 0 : _u.length) && !isCarrierFromCustomerList) {
                initialValues.emails.push(order.forwarders[0].email);
                users.push(order.forwarders[0]);
            }
            (_a = initialValues.emails).push.apply(_a, __spread([((_v = props.quote) === null || _v === void 0 ? void 0 : _v.createdBy) && ((_w = props.quote) === null || _w === void 0 ? void 0 : _w.createdBy.email), order && ((_x = (order.manager || order.createdBy)) === null || _x === void 0 ? void 0 : _x.email)]));
            users.push((_y = props.quote) === null || _y === void 0 ? void 0 : _y.createdBy, order && (order === null || order === void 0 ? void 0 : order.createdBy));
        }
        else if (currentUser.appType.includes(BaseUserAppTYpe.FORWARDER)) {
            (_b = initialValues.emails).push.apply(_b, __spread([((_z = props.quote) === null || _z === void 0 ? void 0 : _z.createdBy) && ((_0 = props.quote) === null || _0 === void 0 ? void 0 : _0.createdBy.email), order && ((_1 = (order.manager || order.createdBy)) === null || _1 === void 0 ? void 0 : _1.email)]));
            users.push.apply(users, __spread([(_2 = props.quote) === null || _2 === void 0 ? void 0 : _2.createdBy, order && (order.manager || order.createdBy)]));
        }
        if ([BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].find(function (t) { return currentUser.appType.includes(t); })) {
            if (props.isPreparation) {
                users.push((order === null || order === void 0 ? void 0 : order.manager) || (order === null || order === void 0 ? void 0 : order.createdBy));
            }
            else {
                if (order && ((_3 = order === null || order === void 0 ? void 0 : order.forwarders) === null || _3 === void 0 ? void 0 : _3.length) && !isCarrierFromCustomerList) {
                    initialValues.emails.push(order.forwarders[0].email);
                    users.push(order.forwarders[0]);
                }
                else if ((_4 = props.quote) === null || _4 === void 0 ? void 0 : _4.createdBy) {
                    initialValues.emails.push((_5 = props.quote) === null || _5 === void 0 ? void 0 : _5.createdBy.email);
                    users.push((_6 = props.quote) === null || _6 === void 0 ? void 0 : _6.createdBy);
                }
            }
        }
        var exist_1 = [];
        if (onlyUsers) {
            return users;
        }
        users = users
            .filter(function (e) {
            if (!e)
                return;
            var res = e && !exist_1.includes(e.email) && Array.isArray(e.appType);
            exist_1.push(e.email);
            return res;
        })
            .map(function (e) {
            var _a;
            return (__assign(__assign({}, e), { 
                // _id: e._id,
                label: e.encoded
                    ? e.firstName
                    : [
                        [e.firstName, e.lastName].filter(function (e) { return e; }).join(' '),
                        e.company.name,
                        (e.encoded &&
                            i18n.getResource(i18n.language, KEYS.APP, "user_app_type_" + ((_a = OPOSITE_USERS[currentUser.appType[0]]) === null || _a === void 0 ? void 0 : _a.toLowerCase()))) ||
                            (e.encoded && 'USER') ||
                            e.email,
                    ]
                        .filter(function (e) { return e; })
                        .join(', '), user: {
                    encoded: e.encoded,
                    appType: e.appType || [],
                    email: e.email,
                }, company: { name: e.company.name } }));
        });
        (_c = initialValues.users).push.apply(_c, __spread(users));
        var uniqueEmails_1 = new Set();
        initialValues.users = initialValues.users.filter(function (user) {
            if (uniqueEmails_1.has(user._id))
                return false;
            uniqueEmails_1.add(user._id);
            return true;
        });
    }
    catch (e) {
        console.log('documentInitialValues error====+++', e);
    }
    initialValues.emails = __spread(new Set(initialValues.emails));
    return initialValues;
};
