import { BaseUserAppTYpe } from '../../../stores/common/types';
export var isHideForwarderToCustomer = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var quote = _a.quote, currentUser = _a.currentUser, accountStore = _a.accountStore;
    var isCarrierFromCustomerList = !((_c = (_b = quote.order) === null || _b === void 0 ? void 0 : _b.createdBy) === null || _c === void 0 ? void 0 : _c.encoded) && ((_f = (_e = (_d = quote === null || quote === void 0 ? void 0 : quote.createdBy) === null || _d === void 0 ? void 0 : _d.company) === null || _e === void 0 ? void 0 : _e.companiesParents) === null || _f === void 0 ? void 0 : _f.length) &&
        quote.createdBy.company.companiesParents
            .map(function (e) { return e.toString(); })
            .includes(quote.order.createdBy.company._id.toString());
    var isCarrier = currentUser.appType.includes(BaseUserAppTYpe.CARRIER);
    var isCustomer = currentUser.appType.includes(BaseUserAppTYpe.CUSTOMER);
    var isSECS = [BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].find(function (e) { return currentUser.appType.includes(e); });
    var hideForwarderToCarrier = (isCustomer || isSECS) && accountStore.isForwarderEnvOnly && !isCarrierFromCustomerList;
    var hideForwarderToCustomer = isCarrier && ((_h = (_g = quote.order) === null || _g === void 0 ? void 0 : _g.forwarders) === null || _h === void 0 ? void 0 : _h.length) && !isCarrierFromCustomerList;
    return {
        hideForwarderToCustomer: hideForwarderToCustomer,
        hideForwarderToCarrier: hideForwarderToCarrier,
    };
};
export var checkHideFoToCU = function (_a) {
    var access = _a.access, quote = _a.quote, currentUser = _a.currentUser, accountStore = _a.accountStore;
    var updatedE = access;
    var _b = isHideForwarderToCustomer({
        quote: quote,
        currentUser: currentUser,
        accountStore: accountStore,
    }), hideForwarderToCarrier = _b.hideForwarderToCarrier, hideForwarderToCustomer = _b.hideForwarderToCustomer;
    if (hideForwarderToCarrier) {
        updatedE = access === BaseUserAppTYpe.FORWARDER ? BaseUserAppTYpe.CARRIER : access;
    }
    if (hideForwarderToCustomer) {
        updatedE = access === BaseUserAppTYpe.FORWARDER ? BaseUserAppTYpe.CUSTOMER : access;
    }
    return updatedE;
};
